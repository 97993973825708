<template>
<div id="footer" class="absolute bottom-0 left-0 w-full">
    Waterside. All rights reserved © 2024
</div>
</template>

<script>
export default {

}
</script>

<style src="./TheFooter.css" scoped>

</style>
