<template>
<div class="social-bar d-sm-flex flex-column position-fixed animate__animated animate__delay-3s animate__fadeIn">
    <div class="social-bar-item">
        <a href="https://www.facebook.com/watersidecafekitchen" target="_blank"><img src="@/assets/facebook.webp" alt="Lien vers Facebook"></a>
    </div>
    <div class="social-bar-item">
        <a href="https://instagram.com/watersidecafekitchen?utm_medium=copy_link" target="_blank"><img src="@/assets/instagram.webp" alt="lien vers Instagram"></a>
    </div>
    <div class="social-bar-item">
        <a href="https://www.tripadvisor.fr/Restaurant_Review-g297952-d13355959-Reviews-Waterside_Cafe_Kitchen_Aqua_Park-Port_El_Kantaoui_Sousse_Governorate.html" target="_blank"><img src="@/assets/tripadvlogo.webp" alt="Lien vers tripadvisor"></a>
    </div>
    <div class="social-bar-item">
        <a href="https://www.acquapalace.com/" target="_blank"><img src="@/assets/acqualogoancien.jpeg" alt="Lien vers AcquaPalace"></a>
    </div>
</div>
</template>

<script>
export default {}
</script>

<style src="./SocialBar.css" scoped>

</style>
