<template>
  <div class="language-selector dropdown">
    <button
      class="language-button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="current-language mr-2"> {{ currentLang }} </span>
      <img :src="currentLangFlag" />
      <i class="fas fa-chevron-down m-2"></i>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdownMenuButton"
    >
      <li
        @click="setLang('fr')"
        class="dropdown-item d-flex justify-content-between align-content-center"
      >
        <span>Français</span
        ><img class="header-list-flag" src="@/assets/frflag.webp" />
      </li>
      <li
        @click="setLang('en')"
        class="dropdown-item d-flex justify-content-between align-content-center"
      >
        <span>English</span
        ><img class="header-list-flag" src="@/assets/enflag.webp" />
      </li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    currentLangFlag() {
      return this.$store.getters['lang/getCurrentFlag'];
    },
  },
  methods: {
    onHoverLang() {
      $('div.dropdown').hover(function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(400);
      }, function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
      });
    },
    setLang(val) {
      this.$store.commit('lang/setLang', val);
    },
  },
  mounted() {
    this.onHoverLang();
  }
};
</script>

<style src="./TheLanguageSelector.css" scoped></style>
