<template>
  <div class="navbar navbar-dark animate__animated animate__slow" id="navbar">
    <a :href="linksL[lang].Accueil.link" class="navbar-brand d-xl-none"><img src="@/assets/logo_v1_whitebg.jpeg"></a>
    <a :href="linksL[lang].Accueil.link">
      <img class="d-none d-xl-block" src="@/assets/logo_v1_whitebg.jpeg">
    </a>
    <navbarList class="d-none flex-grow-1 pt-5" :list="linksL[lang]" :position="end"></navbarList>
    <the-language-selector
      class="flex-shrink-1 d-none d-xl-flex pt-3"
    ></the-language-selector>
    <the-language-selector
      class="flex-shrink-1 d-xl-none pl-5"
    ></the-language-selector>
    <button class="navbar-toggler d-xl-none" type="button" data-toggle="collapse" data-target="#myNavbarToggler7" aria-controls="myNavbarToggler7" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse order-5" id="myNavbarToggler7">
      <ul class="navbar-nav" data-toggle="collapse" data-target="#myNavbarToggler7">
        <ul v-for="item in linksL[lang]" :key="item.id">
          <li class="nav-item">
            <a :href="`${item.link}`">{{ item.name }}</a>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
import TheLanguageSelector from '@/components/TheLanguageSelector/TheLanguageSelector.vue';
import navbarList from '@/components/TheNavbarList/TheNavbarList.vue'

export default {
  data() {
    return {
      linksL: {
        fr: {
          Accueil: {
            link: "#Accueil",
            name: "Accueil",
            id: 0
          },
          Menu: {
            link: "#Menu",
            name: "Menu",
            id: 1  
          },
          localisation: {
            link: "#CLO",
            name: "localisation",
            id: 1
          },
          Vue: {
            link: "#Vue",
            name: "Vue 3D",
            id: 3  
          },
          galerie: {
            link: "#Galerie",
            name: "Galerie",
            id: 3  
          },
          Apropos: {
            link: "#About",
            name: "A propos",
            id: 4
          }
        },
        en: {
          Accueil: {
            link: "#Accueil",
            name: "Home",
            id: 0
          },
          Menu: {
            link: "#Menu",
            name: "Menu",
            id: 1  
          },
          localisation: {
            link: "#CLO",
            name: "localisation",
            id: 1
          },
          Vue: {
            link: "#Vue",
            name: "3D Tour",
            id: 3  
          },
          galerie: {
            link: "#Galerie",
            name: "Gallery",
            id: 3  
          },
          Apropos: {
            link: "#About",
            name: "About",
            id: 4
          }
        },
      },
      end: "end",
    };
  },
  components: {
    TheLanguageSelector,
    navbarList
  },
  computed: {
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.scrollY > 250) {
        document.getElementById("navbar").classList.add("unhide");
      } else {
        document.getElementById("navbar").classList.remove("unhide");
      }
      /*$(window).scroll(function() {
        if ($(this).scrollTop() > 250) {
          $('.navbar').stop().fadeIn(400); 
        } else {
          $('.navbar').stop().fadeOut(500);
        }
      });*/
    },
  },
};
</script>

<style src="./TheNavbar.css" scoped></style>
