<template>
<ul class="list d-xl-flex" :style="{ 'justify-content': `${position}`}">
    <li class="list-title" v-for="item in list" :key="item.id">
            <a :href="`${item.link}`">{{ item.name }}</a>
    </li>
</ul>
</template>

<script>
export default {
    components: {},
    props: {
        list: {
            type: Object,
            required: true
        },
        position: {
            type: String,
            required: true
        }
    },
}
</script>

<style src="./TheNavbarList.css" scoped>

</style>
